<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- DIALOG BACK -->
  <m-ui-back-button></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Jakość organizacji pracy</span>
      </div>
    </div>

    <div class="source-data-date-tab" *ngIf="qualityTasks?.source_data_date">
      Dane aktualne na: {{ qualityTasks.source_data_date | date:'dd-MM-y' }}
    </div>
  </div>

  <div class="mark-window-left">
    <img [src]="'icon_quality.png' | asset:'ui'">
  </div>

  <div
    *ngIf="qualityTasks && paginationDate"
    class="navigation loading-opacity"
  >
    <!-- PREV -->
    <button
      class="previous"
      (click)="prev()"
      [disabled]="!paginationDate.allowPrev.month"
    >
      <i class="far fa-chevron-square-left nav-arrow"></i>
    </button>

    <!-- MONTH -->
    <span class="value">
      {{ owDate.month_text_normal }}
    </span>

    <!-- NEXT -->
    <button
      class="next"
      (click)="next()"
      [disabled]="!paginationDate.allowNext.month"
    >
      <i class="far fa-chevron-square-right nav-arrow"></i>
    </button>
  </div>

  <div class="frame loading-opacity" *ngIf="qualityTasks">
    <ng-container *ngIf="qualityTasks.tasks.length; else noItem">
      <ow-perfect-scrollbar [scrollGradient]="qualityTasks.tasks.length">
        <p class="description">
          Parametr JAKOŚĆ ORGANIZACJI PRACY używany jest jako mnożnik, który może podnieść wartość nagród,
          które co miesiąc otrzymasz za realizację zadań biznesowych – stałych i dodatkowych wybranych do 20 dnia
          danego miesiąca. <b>Dbaj o dobrą organizację pracy, by zyskać jeszcze więcej Złotych Talarów i Banknotów.</b>
        </p>

        <div class="static-container">
          <div *ngIf="qualityTasks.tasks.length" class="loading-opacity">
            <!-- TITLE TABLE -->
            <m-ui-separator class="left" title="Twój wynik"></m-ui-separator>

            <!-- TABLE -->
            <table>
              <thead>
              <tr>
                <th>Nazwa zadania</th>
                <th>Ilość przekroczeń</th>
                <th>Mnożnik Złotych Talarów</th>
                <th>Mnożnik Banknotów</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let task of qualityTasks.tasks">
                <td>{{ task.name }}</td>
                <td>{{ task.result_value }}</td>
                <td>{{ task.money_percent | number:'1.2-2' }}%</td>
                <td>{{ task.material_percent | number:'1.2-2' }}%</td>
              </tr>
              </tbody>
            </table>
          </div>

          <!-- TITLE TABLE -->
          <m-ui-separator class="left" title="Skala oceny parametru"></m-ui-separator>

          <!-- TABLE STATIC -->
          <table class="static">
            <thead>
            <tr>
              <th>Parametry organizacji pracy</th>
              <th>Ilość przekroczeń w miesiącu</th>
              <th>Mnożnik Banknotów i Złotych Talarów</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                Codzienna, wzorowa organizacja czasu pracy<br/>
                (brak spóźnień i przekroczeń czasu przerw)
              </td>
              <td>0</td>
              <td>105%</td>
            </tr>
            <tr>
              <td>
                Pojedyncze niedociągnięcia w organizacji czasu pracy
              </td>
              <td>od 1 do 2</td>
              <td>90%</td>
            </tr>
            <tr>
              <td>
                Kilkukrotne niedociągnięcia w organizacji czasu pracy
              </td>
              <td>od 3 do 5</td>
              <td>60%</td>
            </tr>
            <tr>
              <td>
                Niewłaściwa organizacja czasu pracy<br/>
                (wielokrotne spóźnienia lub przekroczenia czasu przerw)
              </td>
              <td>powyżej 5</td>
              <td>0%</td>
            </tr>
            </tbody>
          </table>

          <p class="description">
            Ważne! Żeby brać udział w rankingach nie możesz mieć więcej niż 5 przekroczeń w miesiącu.
          </p>
        </div>
      </ow-perfect-scrollbar>
    </ng-container>
    <ng-template #noItem>
      <p class="flex-center no-data"><b>Brak wyników</b></p>
    </ng-template>
  </div>
</div>
