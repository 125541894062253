import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { PLAYER_TITLES } from "../../../../../../core/consts/core/player-titles";
import { OwDate } from "../../../../../../core/utility/ow-date.class";
import { TitleId, TitleIdBranchHistory } from "../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../player/providers/player.service";
import { Branch } from "../../../../interfaces/branch.interface";
import { ApiPlayerService } from "../../../hud/api/custom/services/api-player.service";

@Injectable({
  providedIn: "root",
})
export class DashboardRoleChangeService {
  private branchSubject = new BehaviorSubject<Branch | undefined>(null);
  private branchNameSubject = new BehaviorSubject<string | undefined>(null);

  owDate: OwDate;

  constructor(
    private playerService: PlayerService,
    private apiPlayerService: ApiPlayerService
  ) {}

  get branch$(): Observable<Branch | undefined> {
    return this.branchSubject.asObservable();
  }

  get branchName$(): Observable<string | undefined> {
    return this.branchNameSubject.asObservable();
  }

  checkRoleChanges(): void {
    const { branch_id, title_id, title_id_branch_history } = this.playerService.player;
    const actualHistoryItem = this.getActualHistoryItem(title_id_branch_history);

    const branch = this.determineBranch(branch_id, title_id, actualHistoryItem);
    this.branchSubject.next(branch);
  }

  getDataWithBranchInfo(callback: () => void, selectedBranch?: Branch) {
    if (selectedBranch) {
      this.apiPlayerService
        .branches({
          page: 1,
          limit: 100,
          year: this.owDate.year,
          month: this.owDate.month,
          player_id: !this.playerService.isActiveMe ? this.playerService.player.id : undefined,
        })
        .subscribe((resp: any) => {
          const branches = resp.body.sort((a, b) => a.name.localeCompare(b.name));
          const branch = branches.find(item => item.branch_id === selectedBranch.branch_id);
          const branchName = !branch.manager_player_id ? `${branch.name} - Kierownik niezarejestrowany` : branch.name;

          this.branchSubject.next(branch);
          this.branchNameSubject.next(branchName);

          callback();
        });
    }
  }

  private getActualHistoryItem(history: TitleIdBranchHistory[]): TitleIdBranchHistory {
    return history.find(item => item.year === this.owDate.year && item.month === this.owDate.month);
  }

  private determineBranch(branch_id: number, title_id: TitleId, actualHistoryItem: TitleIdBranchHistory): Branch | undefined {
    if (actualHistoryItem) {
      if (this.hasRoleChanged(branch_id, title_id, actualHistoryItem)) {
        return this.createBranch(branch_id);
      }
    } else if (title_id.id === PLAYER_TITLES.KIEROWNIK.id) {
      return this.createBranch(branch_id);
    }
    return undefined;
  }

  private hasRoleChanged(branch_id: number, title_id: TitleId, historyItem: TitleIdBranchHistory): boolean {
    if (title_id?.id < PLAYER_TITLES.KIEROWNIK.id) {
      return false;
    } else {
      return (
        (branch_id !== historyItem?.branch_id || title_id.id !== historyItem?.title_id) &&
        (historyItem?.title_id === title_id.id || historyItem?.title_id < PLAYER_TITLES.KIEROWNIK.id)
      );
    }
  }

  private createBranch(branch_id: number): Branch {
    return {
      branch_id: branch_id,
      role_changed: true,
      city: undefined,
      name: undefined,
    };
  }
}
